import React from 'react'
import { P } from '@farewill/ui'
import PropTypes from 'prop-types'

// TODO: Split out into Error and FieldError?
const Error = ({ id, href, tag = 'li', submitButtonId, text, ...props }) => {
  const Component = tag

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...props}>
      <a
        href={href}
        onClick={(e) => {
          e.preventDefault()
          const fieldTarget = document.getElementById(id)
          if (fieldTarget) {
            fieldTarget.focus()
          } else if (submitButtonId) {
            // TODO: Update @farewill/ui Button to support forward refs and use
            // a ref here instead.
            document.getElementById(submitButtonId)?.focus()
          }
        }}
      >
        {text}
      </a>
    </Component>
  )
}

Error.propTypes = {
  href: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  submitButtonId: PropTypes.string.id,
  tag: PropTypes.oneOfType([
    PropTypes.string, // HTML tag name
    PropTypes.elementType, // React component
  ]),
  text: PropTypes.string.isRequired,
}

const Errors = ({
  errors,
  label,
  nonFieldErrors = {},
  renderAs = 'list',
  submitButtonId,
}) => {
  const hasNonFieldErrors = Object.keys(nonFieldErrors).length > 0
  const fieldErrorMessages = Object.entries(errors).map(([id, text]) => ({
    id,
    text,
  }))
  const nonFieldErrorMessages = Object.entries(nonFieldErrors).map(
    ([id, text]) => ({ id, text })
  )

  if (renderAs === 'paragraphs') {
    return (
      <>
        {fieldErrorMessages.map(({ id, text }, index) => (
          <Error
            key={index}
            href={`#${id}`}
            id={id}
            submitButtonId={submitButtonId}
            tag={P}
            text={text}
          />
        ))}
      </>
    )
  }

  // FIXME: Combine this and if (renderAs === 'paragraphs') { above?
  if (hasNonFieldErrors) {
    return (
      <>
        {nonFieldErrorMessages.map(({ id, text }, index) => (
          <Error
            key={index}
            href={`#${submitButtonId}`}
            id={id}
            submitButtonId={submitButtonId}
            tag={P}
            text={text}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {fieldErrorMessages.length && (
        <P margin={[0, 0, 'XS']}>
          {label ?? 'Please fix these errors to continue:'}
        </P>
      )}
      <ul>
        {fieldErrorMessages.map(({ id, text }, index) => (
          <Error key={index} href={`#${id}`} id={id} text={text} />
        ))}
      </ul>
    </>
  )
}

Errors.propTypes = {
  errors: PropTypes.object.isRequired,
  nonFieldErrors: PropTypes.object.isRequired,
  label: PropTypes.string,
  renderAs: PropTypes.oneOf(['list', 'paragraphs']),
  submitButtonId: PropTypes.string.isRequired,
}

export default Errors
