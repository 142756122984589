import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormStatusMessage } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import isEmpty from 'lodash/fp/isEmpty'

import UnknownError from './components/UnknownError'
import Errors from './components/Errors'
import { API_ERROR_KEY, UNKNOWN_ERROR_TEXT } from './constants'

const StyledFormStatusMessage = styled(FormStatusMessage)`
  margin: 0 0 ${GTR.L};
`

const FormStatus = ({
  errors,
  isSubmitting,
  label,
  renderAs,
  submitButtonId,
}) => {
  const wrapperEl = useRef()

  const showUnknownError = errors[API_ERROR_KEY] === UNKNOWN_ERROR_TEXT

  /**
   * The <FormStatus/> component should be focused on each time the
   * isSubmitting boolean goes from true to false (and the component
   * is visible).
   */
  useEffect(() => {
    if (!isSubmitting) wrapperEl.current?.focus()
  }, [isSubmitting])

  if (isEmpty(errors)) return null
  // errors in an object with keys as field names and values as error messages
  // filter out the API_ERROR_KEY as it's a general error not related to a
  // specific field.
  const { fieldErrors, nonFieldErrors } = Object.entries(errors).reduce(
    (acc, [key, value]) => {
      if (key === API_ERROR_KEY) {
        acc.nonFieldErrors[key] = value
      } else {
        acc.fieldErrors[key] = value
      }
      return acc
    },
    { fieldErrors: {}, nonFieldErrors: {} }
  )
  const title = 'Something went wrong'

  return (
    <div ref={wrapperEl} role="alert" tabIndex={-1} title={title}>
      <StyledFormStatusMessage status="error" title={title}>
        {showUnknownError ? (
          <UnknownError />
        ) : (
          <Errors
            errors={fieldErrors}
            label={label}
            nonFieldErrors={nonFieldErrors}
            renderAs={renderAs}
            submitButtonId={submitButtonId}
          />
        )}
      </StyledFormStatusMessage>
    </div>
  )
}

FormStatus.propTypes = {
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  label: PropTypes.string,
  renderAs: PropTypes.oneOf(['list', 'paragraphs']),
  submitButtonId: PropTypes.string.isRequired,
}

export default FormStatus
